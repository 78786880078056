import React, { useEffect, useRef, useState } from "react";
import "./Register.css";
import logo from "../assets/images/logo.svg";
import line from "../assets/images/line.svg";
import video from "../assets/images/video.png";
import interactive from "../assets/images/interactive.svg";
import lifetime from "../assets/images/lifetime.svg";
import flexible from "../assets/images/flexible.svg";
import user from "../assets/images/user.png";
import { Link, NavLink, useHistory } from "react-router-dom";
import jQuery from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { HashLink } from "react-router-hash-link";
import {
  countries,
  states,
  age_groups,
  instruments,
} from "../assets/data/DropdownData";
import { registerUser, resendOtp, sendOtp, verifyOtp } from "../Services/singup.services";
import { toast } from "react-toastify";
import ReactPlayer from "react-player/youtube";
import { AiFillCheckCircle, AiFillPlayCircle, AiOutlineCheck, AiOutlineCheckCircle } from "react-icons/ai";
import { reviewData } from "../assets/data/review.data";
import { Image, Nav } from "react-bootstrap";
import { FaCheck } from 'react-icons/fa';
import { TrackGoogleAnalyticsEvent } from "../utils/ga4";
import AssessmentMobileLogin from "../components/AssessmentMobileLogin";
import MobileOtp from "../components/MobileOtp";
import AssessmentForm from "../components/AssessmentForm";
import WelcomeCard from "../components/WelcomeCard";
import Carousel1 from "../components/Carousal/Carousal1";
import Carousel2 from "../components/Carousal/Carousal2";
import Carousel3 from "../components/Carousal/Carousal3";
import axios from "axios";
import DL1 from "../assets/images/DL1.png"
import DL2 from "../assets/images/DL2.png"
import DL3 from "../assets/images/DL3.png"

interface IPhone {
  phone: string;
  countryCode: string;
  utms: {
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    utm_content: string;
  };
}

interface IRegister {
  country: string;
  firstName: string;
  lastName: string;
  email: string;
  ageGroup: string;
  state: string;
  instrument: string;
}

let api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API || "http://localhost:8000/api",
});


const RegisterStudent = () => {

  const StarSvg = () => (
    <span className="mr-2">
      <svg
        width="12"
        height="11"
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.9083 10.3425C9.9083 10.6825 9.74827 10.8325 9.43827 10.6825L6.88829 9.3925C6.7115 9.316 6.52092 9.27652 6.32829 9.27652C6.13566 9.27652 5.94508 9.316 5.76829 9.3925L3.20829 10.6525C2.90829 10.8025 2.69827 10.6525 2.74827 10.3125L3.17826 7.49249C3.19613 7.30046 3.17527 7.1068 3.11686 6.92299C3.05846 6.73919 2.96374 6.569 2.8383 6.4225L0.838297 4.4225C0.598297 4.1725 0.67829 3.9225 1.01829 3.8725L3.8383 3.41249C4.026 3.37122 4.20303 3.29119 4.35801 3.17754C4.51299 3.06388 4.64253 2.91911 4.73832 2.75249L6.06828 0.2325C6.23828 -0.0775 6.4883 -0.0775 6.64829 0.2325L7.95829 2.77249C8.05684 2.93686 8.18723 3.07991 8.34178 3.19324C8.49632 3.30657 8.67193 3.38791 8.85832 3.4325L11.6783 3.91249C12.0083 3.97249 12.0883 4.2225 11.8483 4.4625L9.84831 6.4625C9.72089 6.60605 9.62413 6.77412 9.56394 6.95639C9.50376 7.13866 9.48139 7.33129 9.49827 7.52249L9.9083 10.3425Z"
          fill="#ECB62B"
        />
      </svg>
    </span>
  );


  const ReviewCard = (data: any) => {
    const cardData = data.data;
    return (
      <div className="item">
        <div className="p-3 bg-white mr-3 card-sec d-flex flex-column justify-content-between">
          <p>{cardData.content}</p>
          <div className="row">
            <div className="col-auto" style={{ width: 80, height: 50 }}>
              <img src={cardData.img} alt="user" width={"100%"} style={{ borderRadius: "50%" }} />
            </div>
            <div className="col pl-0">
              <h6 className="mb-0">{cardData.name}</h6>
              {cardData?.bio &&
                <span className="text-secondary" style={{ fontSize: 10 }}>{cardData.bio}</span>
              }
              <div className="star">
                {Array.from({ length: cardData.rating }, (_, i) => (
                  <StarSvg />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };



  const history = useHistory();
  const [disablePhone, setDisablePhone] = useState<boolean>(false);
  const [otpSent, setOtpSent] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [phoneInput, setPhoneInput] = useState<IPhone>({
    phone: "",
    countryCode: "+91",
    utms: {
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      utm_content: "",
    },
  });
  const [componentIndex, setComponentIndex] = useState<number>(1);
  const [studentId, setStudentId] = useState<any>();
  const [passcode, setPasscode] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [formDetails, setFormDetails] = useState<any>();

  function handlePhoneNumberChange(e: any, data: any) {
    setPhoneInput({
      ...phoneInput,
      phone: e.slice(data.dialCode.length),
      countryCode: "+" + data.dialCode,
    });
  }

  const handleChangeValue = (e: any) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value })
  }




  const sendOTP = async () => {
    setLoading(true);
    await api({
      method: "post",
      url: "/signup/phone-signup",
      data: phoneInput,
    }).then((res) => {
      if (res.status === 200) {
        setComponentIndex(componentIndex + 1)
        setStudentId(res.data.userId);
        sessionStorage.setItem("phone", phoneInput.phone)
      }
    }).catch(err => {
      toast.error(err.response.data)
    })
    setLoading(false)
  };


  const verifyOtp = async () => {
    const payload = {
      otp: passcode.passcode,
      userId: studentId
    }
    setLoading(true);
    await api({
      method: "post",
      url: "/signup/submit-otp",
      data: payload,
    }).then((res) => {
      if (res.status === 200) {
        setComponentIndex(componentIndex + 1)
        setStudentId(res.data)
      }
    }).catch(err => {
      console.log(err)
      toast.error(err.response.data)
    })
    setLoading(false)
  };


  const RegisterUser = async () => {
    const payload = {
      userId: studentId.userId,
      firstName: formDetails.firstName,
      lastName: formDetails.lastName,
      email: formDetails.email,
      country: formDetails.country,
      state: formDetails.state,
      musicTherapyFor: formDetails?.musicTherapyForName || 'For myself',
    }
    setLoading(true);
    await api({
      method: "post",
      url: "/signup/complete-signup",
      data: payload,
    }).then((res) => {
      if (res.status === 200) {
        console.log(res.data, "otp")
        setComponentIndex(componentIndex + 1)
      }
    }).catch(err => {
      console.log(err)
      toast.error(err.response.data)
    })
    setLoading(false)
  };


  const resentOTP = async () => {
    const payload = {
      phone: phoneInput.phone,
      countryCode: phoneInput.countryCode,
    }
    await api({
      method: "post",
      url: "/signup/phone-signup",
      data: payload,
    }).then((res) => {
      if (res.status === 200) {
        toast.success("OTP sent")
      }
    }).catch(err => {
      toast.error(err.response.data.message)
    })
  };



  const renderComponents = () => {
    switch (componentIndex) {
      case 1:
        return <AssessmentMobileLogin
          phoneInput={phoneInput}
          handlePhoneNumberChange={handlePhoneNumberChange}
          onOTPSend={sendOTP}
          loading={loading}
        />;
      case 2:
        return <MobileOtp
          setComponentIndex={setComponentIndex}
          setPasscode={setPasscode}
          passcode={passcode}
          onVerifyOtp={verifyOtp}
          loading={loading}
          onResentOTP={resentOTP}
        />;
      case 3:
        return <AssessmentForm handleChangeValue={handleChangeValue} onRegisterUser={RegisterUser} loading={loading} />;
      case 4:
        return <WelcomeCard />;
      default:
        return <AssessmentMobileLogin
          phoneInput={phoneInput}
          handlePhoneNumberChange={handlePhoneNumberChange}
          onOTPSend={sendOTP}
          loading={loading}
        />
    }
  }


  useEffect(() => {
    let interval: any;
    if (otpSent && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [otpSent, seconds, disablePhone]);

  useEffect(() => {
    if (seconds <= 0) {
      setOtpSent(false)
    }
  }, [seconds]);


  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utm_source = searchParams.get("utm_source") as string;
    const utm_medium = searchParams.get("utm_medium") as string;
    const utm_campaign = searchParams.get("utm_campaign") as string;
    const utm_content = searchParams.get("utm_term") as string;
    setPhoneInput({
      ...phoneInput,
      utms: {
        utm_source: utm_source || "",
        utm_medium: utm_medium || "",
        utm_campaign: utm_campaign || "",
        utm_content: utm_content || "",
      }
    })
  }, [window.location.search])


  return (
    <div className="studentNew">
      <nav className="navbar navbar-expand-lg" id="top">
        <Link className="navbar-brand py-4" to="#">
          <img src={logo} alt="Dhunguru" />
        </Link>
      </nav>

      <section>
        <div className="container mt-3 mt-lg-4">
          <div className="row">
            <div className="col-lg-7 mb-4 mb-lg-5 order-2 order-lg-1">
              <div className="d-none d-lg-block">
                <h1 className="for-all-ages">
                  Music Therapy For All Age
                </h1>
                <h1 className="for-all-ages">
                  Groups with{" "}
                  <i>
                    Qualified Therapists
                    <img src={line} alt="" aria-disabled="true" />
                  </i>
                </h1>
                <div className="row">
                  <div className="col-12 mt-2">
                    <OwlCarousel
                      items={1}
                      className="owl-carousel owl-theme"
                      loop={true}
                      autoplay={true}
                      autoplayTimeout={3000}
                      dots={true}
                      nav={false}
                    >
                      <div className="item">
                        <div>
                          <Image src={DL1} height="100%" width="100%" />
                        </div>
                      </div>
                      <div className="item">
                        <div>
                          <Image src={DL2} height="100%" width="100%" />
                        </div>
                      </div>
                      <div className="item">
                        <div>
                          <Image src={DL3} height="100%" width="100%" />
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
              {/* <div className="mt-4">
                <p className="font_wight_500" style={{ fontSize: 16 }}>
                  Music therapy is a safe and effective way to blend music and psychology, using its power to improve <i style={{ fontWeight: "600" }}>physical, emotional, cognitive,</i> and <i style={{ fontWeight: "600" }}>social well-being.</i>
                </p>
                <p style={{ fontSize: 15, fontWeight: "400" }}>
                  It involves music-based interventions by qualified professionals to achieve specific therapeutic goals and improve overall well-being.
                </p>
              </div> */}

              <div className="row mt-4">
                <div className="col-lg-auto col-12 mb-3">
                  <div className="interactive p-3">
                    <div className="row">
                      <div className="col-auto pr-0">
                        <img src={interactive} alt="1:1 Interactive Classes" />
                      </div>
                      <div className="col">
                        <b className="d-block">Personalized 1:1</b>
                        Sessions
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-auto col-12 mb-3">
                  <div className="lifetime p-3">
                    <div className="row">
                      <div className="col-auto pr-0">
                        <img src={lifetime} alt="Lifetime Access" />
                      </div>
                      <div className="col">
                        <b className="d-block">Experienced Music</b>
                        Therapists
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-auto col-12 mb-3">
                  <div className="flexible p-3">
                    <div className="row">
                      <div className="col-auto pr-0">
                        <img src={flexible} alt="Flexible Timings" />
                      </div>
                      <div className="col">
                        <b className="d-block">Flexible Session</b>
                        Scheduling
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-2 d-lg-none">
              <div className="bg-white m-2 p-3 rounded shadow-sm">
                      <p style={{ fontSize: 15, fontWeight: "400" }}>
                      Music therapy involves music-based interventions by certified and experienced therapists to achieve specific goals to improve cognitive, behavioural, social, and emotional functions, enhancing overall well-being.
                      </p>
                    </div>
                {/* <OwlCarousel
                  items={1}
                  className="owl-carousel owl-theme"
                  loop={true}
                  autoplay={true}
                  autoplayTimeout={3000}
                  dots={true}
                  nav={false}
                >
                  <div className="item">
                    <div className="bg-white m-2 p-3 rounded shadow-sm">
                      <p className="font_wight_500" style={{ fontSize: 16 }}>
                        Music therapy is a safe and effective way to blend music and psychology, using its power to improve <i style={{ fontWeight: "600" }}>physical, emotional, cognitive,</i> and <i style={{ fontWeight: "600" }}>social well-being.</i>
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    
                  </div>
                </OwlCarousel> */}
              </div>
              <div className="row">
                <div className="col-12 mt-2">
                  <OwlCarousel
                    className="owl-theme review-sec"
                    loop={true}
                    margin={10}
                    autoplay={true}
                    autoplayTimeout={3000}
                    dots={false}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      600: {
                        items: 2,
                      },
                      1000: {
                        items: 2.25,
                      },
                    }}
                  >
                    {reviewData.map((review: any) => (
                      <ReviewCard data={review} />
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mb-4 mb-lg-5 order-1 order-lg-2 pl-3 pl-xl-5">
              <div className="mb-4 d-block d-lg-none">
                <h1 className="for-all-ages">
                  Music Therapy For All Age Groups with{" "}
                </h1>
                <h1 className="for-all-ages">
                  <i>
                    Qualified Therapists
                    <img src={line} alt="" aria-disabled="true" />
                  </i>
                </h1>
              </div>
              <div className="row d-block d-lg-none">
                <div className="col-12 mt-2">
                  <OwlCarousel
                    items={1}
                    className="owl-carousel owl-theme"
                    loop={true}
                    autoplay={true}
                    autoplayTimeout={3000}
                    dots={true}
                    nav={false}
                  >
                    <div className="item">
                      <div>
                        <Image src={DL1} height="100%" width="100%" />
                      </div>
                    </div>
                    <div className="item">
                      <div>
                        <Image src={DL2} height="100%" width="100%" />
                      </div>
                    </div>
                    <div className="item">
                      <div>
                        <Image src={DL3} height="100%" width="100%" />
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
              {renderComponents()}
              <div className="col-12 mt-4 d-none d-lg-block">
              <div className="bg-white m-2 p-3 rounded shadow-sm">
                      <p style={{ fontSize: 15, fontWeight: "400" }}>
                      Music therapy involves music-based interventions by certified and experienced therapists to achieve specific goals to improve cognitive, behavioural, social, and emotional functions, enhancing overall well-being.
                      </p>
                    </div>
                {/* <OwlCarousel
                  items={1}
                  className="owl-carousel owl-theme"
                  loop={true}
                  autoplay={true}
                  autoplayTimeout={3000}
                  dots={true}
                  nav={false}
                >
                  <div className="item">
                    <div className="bg-white m-2 p-3 rounded shadow-sm">
                      <p className="font_wight_500" style={{ fontSize: 16 }}>
                        Music therapy is a safe and effective way to blend music and psychology, using its power to improve <i style={{ fontWeight: "600" }}>physical, emotional, cognitive,</i> and <i style={{ fontWeight: "600" }}>social well-being.</i>
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    
                  </div>
                </OwlCarousel> */}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section >
    </div >
  );
};

export default RegisterStudent;



{/* 

 <div className="form-group">
                <label className="small mb-1 mt-3">Your Name</label>
                <div className="row">
                  <div className="col pr-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                      name="firstName"
                      onChange={onChange}
                    />
                  </div>
                  <div className="col pl-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      name="lastName"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="small mb-1 mt-3">Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="example@gmail.com"
                  name="email"
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="city" className="small mb-1 mt-3">
                  Instrument
                </label>
                <select
                  id="instrument"
                  name="instrument"
                  className="form-control"
                  onChange={onChange}
                >
                  <option value="" disabled selected hidden>
                    Select an instrument
                  </option>
                  {instruments.map((instrument) => (
                    <option value={instrument.value}>
                      {instrument.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="row">
                <div className="col-md-4 col-6 pr-lg-2">
                  <div className="form-group">
                    <label className="small mb-1 mt-3">Age Group</label>
                    <select
                      id="age-group"
                      name="ageGroup"
                      className="form-control"
                      onChange={onChange}
                    >
                      <option value="" disabled selected hidden>
                        Age
                      </option>
                      {age_groups.map((age) => (
                        <option value={age.value}>{age.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4 col-6 pl-lg-2 pr-lg-2">
                  <div className="form-group">
                    <label htmlFor="city" className="small mb-1 mt-3">
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      className="form-control"
                      onChange={onChange}
                    >
                      {countries.map((country) => (
                        <option
                          value={country}
                          selected={country === "India"}
                        >
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {formDetails.country === "India" && (
                  <div className="col-md-4 col-6">
                    <div className="form-group">
                      <label htmlFor="state" className="small mb-1 mt-3">
                        State
                      </label>
                      <select
                        id="state"
                        name="state"
                        className="form-control"
                        onChange={onChange}
                      >
                        <option value="" disabled selected hidden>
                          State
                        </option>
                        {states.map((state) => (
                          <option value={state}>{state}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
              <div className="custom-control custom-checkbox form-group d-flex align-items-start  mt-3">
                <input
                  type="checkbox"
                  className="custom-control-input me-2 mt-1"
                  id="customCheck1"
                  ref={termsRef}
                />
                <label
                  className="custom-control-label small"
                  htmlFor="customCheck1"
                >
                  By Continuing, you agree to Dhunguru's{" "}
                  <a href="https://dhunguru.com/legal#tnc-students">
                    Terms of Use
                  </a>
                  ,{" "}
                  <a href="https://dhunguru.com/legal#privacy">
                    Privacy Policy{" "}
                  </a>
                  and
                  <a href="https://dhunguru.com/refund-policy">
                    {" "}
                    Refund Policy
                  </a>
                </label>
              </div>

               <div className="form-group mb-2 mt-3">
                <button
                  className="btn btn-primary btn-block w-100"
                  onClick={async () => {
                    if (termsRef.current.checked) {
                      const register = await registerUser(formDetails, id, phoneInput.utms);
                      if (register) {
                        window.location.replace("https://dhunguru.com/");
                      }
                    } else toast.warn("Agree to the terms to continue");
                  }}
                >
                  Sign Up
                </button>
              </div>
              <div className="text-center small mt-2">
                Already have an account?{" "}
                <a href="https://dhunguru.com/login">Login</a>
              </div>
*/}