import { Image } from "react-bootstrap";
import UserImage from "../assets/images/UserMusic.png"
import DrumImage from "../assets/images/Drum.png"
import SmilyImage from "../assets/images/Smily.png"
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function WelcomeCard() {
    const history = useHistory()
    const [seconds, setSeconds] = useState<number>(5);

    // useEffect(() => {
    //     let interval: any;
    //     if (seconds > 0) {
    //         interval = setInterval(() => {
    //             setSeconds((seconds) => seconds - 1);
    //         }, 1000);
    //     } else {
    //         clearInterval(interval);
    //         window.location.href = "https://dhunguru.com/login";
    //     }
    //     return () => clearInterval(interval);
    // }, [seconds]);


    return (
        <>
            <div className="bg-white p-4 shadow form-sec">
                <div className="">
                    <h4 className="text-center mb-0">
                        Thank you for sharing your details.
                    </h4> 
                    <h4 className="mt-2 text-center">
                        Our team will get back to you.
                    </h4>
                </div>
                <div className="mx-auto" style={{ width: 228, height: 193 }}>
                    <Image src={UserImage} height={"100%"} width={"100%"} />
                </div>
                <h5 className="fw-bold mt-3">
                    Do you know?
                </h5>
                <div className="text-center mt-2">
                    “Music therapy is a safe and effective way to blend music and psychology, using its power to improve
                    <i className="fw-bold ms-1">
                        physical, emotional, cognitive, and social well-being.”
                    </i>
                </div>
                <div className="d-flex justify-content-around mt-3">
                    <div style={{ width: "80px", height: "60px" }}>
                        <Image src={DrumImage} height={"100%"} width={"100%"} style={{ transform: "rotate(13deg)" }} />
                    </div>
                    <div></div>
                </div>
                <div className="d-flex justify-content-around mt-3">
                    <div></div>
                    <div style={{ width: "100px", height: "104px" }}>
                        <Image src={SmilyImage} height={"100%"} width={"100%"} style={{ transform: "rotate(-16deg)" }} />
                    </div>
                </div>
                {/* <div className="text-center text-secondary text-small">
                    Redirecting you to dashboard in {" "}
                    <span className="fw-bold font_wight_500">
                        {seconds} sec
                    </span>
                </div>

                <div className="text-center text-primary text-small">
                    Unable to Redirect?
                    <span className="fw-bold ms-1 font_wight_500">
                        Click here
                    </span>
                </div> */}
            </div>
        </>
    )
}