import { Col, Image, Row, Spinner } from "react-bootstrap";
import UserImage from "../assets/images/User.png"
import { age_groups, countries, instruments, states } from "../assets/data/DropdownData";
import { useState } from "react";

export default function AssessmentForm({ handleChangeValue, onRegisterUser, loading }: any) {
    const [checkedValue, setCheckedValue] = useState<boolean>(false)
    return (
        <>
            <div className="bg-white p-4 shadow form-sec">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="dhun_form_login_heading">Book your free assessment session
                    </div>
                </div>
                <div>
                    <Row>
                        <Col md={6} >
                            <div className="form-group">
                                <label className="small mb-1 mt-3">First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    name="firstName"
                                    onChange={handleChangeValue}
                                />
                            </div>
                            <div className="form-group">
                                <label className="small mb-1 mt-4">Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    name="lastName"
                                    onChange={handleChangeValue}
                                />
                            </div>
                        </Col >
                        <Col md={6} className="d-flex justify-content-center align-items-center">
                            <div style={{ width: 175, height: 175 }}>
                                <Image src={UserImage} width={"100%"} height={"100%"} />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label className="small mb-1 mt-4">Email Address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="example@gmail.com"
                                    name="email"
                                    onChange={handleChangeValue}
                                />
                            </div>
                            <div className="form-group">
                                <label className="small  mt-4">I require music therapy for</label>
                                <select
                                    id="age-group"
                                    // name="ageGroup"
                                    className="form-control"
                                    onChange={handleChangeValue}
                                    name="musicTherapyForName"
                                    defaultValue={"For myself"}
                                >
                                    {/* <option placeholder="" value="" disabled selected hidden>
                                        For my kid (4-18 years old)
                                    </option> */}
                                    {age_groups.map((age: any, index:number) => (
                                        <option value={age.value} key={index}>{age.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-6 col-6 pl-lg-2 pr-lg-2">
                                    <div className="form-group">
                                        <label htmlFor="city" className="small">
                                            Country
                                        </label>
                                        <select
                                            id="country"
                                            name="country"
                                            className="form-control"
                                            onChange={handleChangeValue}
                                        >
                                            <option value="" disabled selected hidden>
                                                Country
                                            </option>
                                            {countries.map((country: any) => (
                                                <option
                                                    value={country}
                                                >
                                                    {country}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6">
                                    <div className="form-group">
                                        <label htmlFor="state" className="small">
                                            State
                                        </label>
                                        <select
                                            id="state"
                                            name="state"
                                            className="form-control"
                                            onChange={handleChangeValue}
                                        >
                                            <option value="" disabled selected hidden>
                                                State
                                            </option>
                                            {states.map((state) => (
                                                <option value={state}>{state}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="custom-control custom-checkbox form-group d-flex align-items-center mt-4 text-small">
                        <input
                            type="checkbox"
                            className="custom-control-input me-3 rounded-0"
                            id="customCheck1"
                            onChange={(e: any) => setCheckedValue(e.target.checked)}
                            checked={checkedValue}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="customCheck1"
                        >
                            By Continuing, you agree to Dhunguru's{" "}
                            <a href="https://dhunguru.com/legal#tnc-students">
                                Terms of Use
                            </a>
                            ,{" "}
                            <a href="https://dhunguru.com/legal#privacy">
                                Privacy Policy{" "}
                            </a>
                            and
                            <a href="https://dhunguru.com/refund-policy">
                                {" "}
                                Refund Policy
                            </a>
                        </label>
                    </div>
                    <div className="form-group mb-3 mt-5">
                        <button
                        id="submit_form_btn_mt"
                            className=" dhun_btn btn btn-primary btn-block w-100"
                            disabled={!checkedValue}
                            onClick={onRegisterUser}
                        >
                            {loading ? <>
                                <Spinner className="me-1" size="sm" animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                                Sign up
                            </> :
                                <span>Sign up</span>
                            }
                        </button>
                    </div>
                </div>
            </div >
        </>
    )
}