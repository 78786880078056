import { Form, Image, Spinner } from "react-bootstrap";
import LockImage from "../assets/images/Lock.png"
import { useEffect, useState } from "react";

export default function MobileOtp({ setPasscode, setComponentIndex, passcode, onVerifyOtp, loading, onResentOTP }: any) {


    const handlePasscodeChanges = (e: any) => {
        setPasscode({ ...passcode, [e.target.name]: e.target.value })
    }

    return (
        <>
            <div className="bg-white p-4 shadow form-sec">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="dhun_verify_heading">Verify OTP
                    </div>
                    <div style={{ width: 62, height: 62 }}>
                        <Image src={LockImage} height={"100%"} width={"100%"} />
                    </div>
                </div>
                <div style={{ marginTop: 21 }}>
                    <div className="text-secondary text-small">
                        We have sent an  otp to your  mobile number {sessionStorage.getItem("phone")}{" "}
                        <span className="text-secondary fw-bold cursor_pointer font_wight_500" onClick={() => setComponentIndex((prev: any) => prev - 1)}>(Change)</span>

                    </div>
                    <div>
                        <label className="small mb-1 mt-3">Enter OTP</label>
                        <div className="row phone-sec">
                            <div className="col pr-0">
                                <div>
                                    <Form.Control
                                        type="text"
                                        name="passcode"
                                        className="w-100 rounded-1"
                                        onChange={handlePasscodeChanges}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-right text-secondary text-small" style={{ marginTop: 20 }}>
                        Did not receive an OTP?{" "}
                        <span
                            className="resend-otp text-dark cursor_pointer font_wight_500"
                            onClick={onResentOTP}
                        >
                            Resend
                        </span>
                    </div>
                    <div className="form-group mb-2 mt-3">
                        <button
                        id="verify_otp_btn_mt"
                            className=" dhun_btn btn btn-dark btn-block w-100"
                            disabled={passcode?.passcode?.length > 0 ? false : true}
                            onClick={onVerifyOtp}
                        >
                            {loading ? <>
                                <Spinner className="me-1" size="sm" animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                                Verify
                            </>
                                :
                                <span>Verify</span>
                            }
                        </button>
                    </div>
                </div>
            </div >
        </>
    )
}