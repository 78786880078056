import { Image, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import HandImage from "../assets/images/hand.png"
import axios from "axios";

interface IAssessmentMobileLogin {
    handlePhoneNumberChange: any
    onOTPSend: any,
    phoneInput: any,
    loading: boolean
}
export default function AssessmentMobileLogin({ handlePhoneNumberChange, onOTPSend, phoneInput, loading }: IAssessmentMobileLogin) {

    return (
        <>
            <div className="bg-white p-4 shadow form-sec">
                <div className="d-flex align-items-center">
                    <div className="mobile_heading">
                    Book your free assessment session
                    </div>
                    <div className="" style={{ width: 62, height: 62 }}>
                        <Image src={HandImage} height={"100%"} width={"100%"} />
                    </div>
                </div>
                <div className="mobile_heading_phone">
                    <label className="dhun_label">Phone Number</label>
                    <div className="row phone-sec mb-3">
                        <div className="col pr-0">
                            <div className="input-group">
                                <PhoneInput
                                    country={"in"}
                                    onChange={(e: any, data: any) => {
                                        handlePhoneNumberChange(e, data);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-2 mt-3">
                        <button
                            id="send_otp_btn_mt"
                            className=" dhun_btn btn btn-dark btn-block w-100 align-items-center"
                            onClick={onOTPSend}
                            disabled={phoneInput?.phone?.length === 10 ? false : true}
                        >
                            {loading ? <>
                                <Spinner className="me-1" size="sm" animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                                Send OTP
                            </> :
                                <span>Send OTP</span>
                            }

                        </button>
                    </div>
                </div>
                <div className=" d-flex align-items-center mt-4">
                    <svg width="800" height="2" viewBox="0 0 457 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.00109409" y1="0.500001" x2="457.001" y2="1.5" stroke="#ABA8B7" />
                    </svg>
                </div>
                <div className="text-center small dhun_mobile_bottom_label text-secondary text-small">
                    Already have a Dhunguru account?{" "}
                    <a href="https://dhunguru.com/login" className="text-dark cursor-pointer font_wight_500">Login</a>
                </div>

            </div>
        </>
    )
}