import Image1 from "./TestimonialsImages/test1.png"
import Image2 from "./TestimonialsImages/test2.png"
import Image3 from "./TestimonialsImages/test3.png"
import Image4 from "./TestimonialsImages/test4.png"
import Image5 from "./TestimonialsImages/test5.png"
import Image6 from "./TestimonialsImages/test6.png"


export const reviewData = [
  {
    name: "Poonam",
    bio: "mom of an 8-year-old with autism",
    content:
      "My son Abhimanyu always seemed lost in his world. He struggled to connect and communicate; even simple everyday tasks felt overwhelming. We tried everything, but nothing truly reached him. Then, we came across Dhunguru’s music therapy. It was like a miracle! Under Aastha's guidance, Abhimanyu made significant improvements. He started singing along, making eye  contact, and even responding to a few rhythms. Music therapy gave him a voice, a connection, and a sense of peace that I never thought possible.",
    img: Image1,
    rating: 5,
  },
  {
    name: "Rahul",
    bio: "35, with ADHD",
    content:
      "My mind used to be a constant racetrack, with thoughts and anxieties swirling like a never-ending storm. Focusing? Impossible. Sleep? A distant dream. I felt like a failure, trapped in a chaotic world. Music therapy became my anchor. Through calming melodies and gentle rhythms, my mind started to slow down. I learned to breathe, to focus, and to find solace in the present moment. It wasn't easy, but with each session, the storm clouds lifted. Now, I can manage my ADHD, embrace its unique energy, and finally live a life in tune with myself.",
    img: Image2,
    rating: 5,
  },
  {
    name: "Pooja",
    bio:"58, struggling with stress and anxiety",
    content:
      "After years of juggling work, family, and a demanding schedule, my body and mind rebelled. Stress kept me awake at night, anxiety gnawed at my confidence, and the joy seemed to have drained from life. The assessment helped, and it felt like a band-aid. My therapist created a safe space where I could melt away my worries through guided meditation music. ",
    img: Image3,
    rating: 4,
  },
  {
    name: "Shastri Narayana",
    bio:"28-year-old working professional",
    content:
      "I stumbled upon this music therapy site during a stressful period in my life. The calming tunes and guided relaxation sessions have become my go-to stress relief methods. I started to sense some positive effects after 3-4 music therapy sessions. However, it keeps getting better with each session.",
    img: Image4,
    rating: 5,
  },
  {
    name: "Rajat Sharma",
    content:
      "As a parent, navigating the complexities of raising a child with speech impairment can be daunting. The creative expression facilitated through music has significantly improved my child's interaction skills.",
    img: Image5,
    rating: 4,
  },


  {
    name: "Unknown",
    bio: "31, father of a child with ADHD",
    content:
      "As a parent of a child with ADHD, I am amazed at the positive changes I've seen since incorporating music therapy into our daily routine. The rhythmic interventions have improved my child's focus and attention, significantly impacting academic and social settings.",
    img: Image6,
    rating: 5,
  },


];
